import React from 'react';
import ReactQuill from 'react-quill';
import {
    FlexColumns,
    ImageUploader,
    Input,
    Select,
    Textarea,
    TFunction,
    Typography,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateArticleDto } from '@escapenavigator/types/dist/article/create-article.dto';
import { CountriesEnum } from '@escapenavigator/types/dist/shared/enum/countries.enum';
import { ImageTypeEnum } from '@escapenavigator/types/dist/upload/enum/image-type.enum';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { CitiesListBuilder } from 'src/components/cities-list-builder';
import { useApi } from 'src/providers/api/context';

import 'react-quill/dist/quill.snow.css';
import styles from './index.module.css';

type Props = {
    close: () => void;
    recordId?: number;
    t: TFunction;
};

export const ArticleModal: React.FC<Props> = ({ close, t, recordId }) => {
    const { articles, uploads } = useApi();

    const { record, loading, error } = useGetOne(+recordId, articles.getOne);
    const languages = Object.values(CountriesEnum).map((el) => ({
        key: el,
        content: el,
    }));

    const {
        save,
        remove,
        updating,
        removing,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: recordId ? articles.update : articles.create,
        removeRequest: articles.remove,
        recordId,
        close,
    });

    return (
        <RestForm
            t={ t }
            loading={ loading }
            updating={ updating || removing }
            save={ save }
            error={ error }
            softError={ softError }
            recordId={ recordId }
            remove={ remove }
            validate={ validateByDto(t) }
            title="Article"
            close={ close }
            initialValues={ serializeRecord(CreateArticleDto, record) }
        >
            { ({
                values, errors, touched, handleChange, setFieldValue,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Input
                            label="Title"
                            value={ values.title }
                            onChange={ handleChange('title') }
                            error={ touched.title && errors.title }
                            required={ true }
                        />
                        <Textarea
                            label="Description"
                            value={ values.description }
                            onChange={ handleChange('description') }
                            error={ touched.description && errors.description }
                            required={ true }
                        />

                        <div className={ styles.editor_wrap }>
                            <Typography.Text
                                className={ styles.text }
                                view="primary-small"
                                weight="medium"
                            >
                                Content *
                            </Typography.Text>
                            <ReactQuill
                                value={ values.content || '' }
                                onChange={ (content) => setFieldValue('content', content) }
                                theme="snow"
                            />
                        </div>

                        <CitiesListBuilder
                            error={ touched.cities && (errors.cities as string) }
                            citiesList={ values.cities || [] }
                            onChange={ (emails) => setFieldValue('cities', emails) }
                        />

                        <Select
                            dataTestId="languages"
                            multiple={ true }
                            block={ true }
                            label="Language"
                            options={ languages }
                            selected={ values.languages }
                            allowUnselect={ true }
                            onChange={ ({ selectedMultiple }) => {
                                setFieldValue(
                                    'languages',
                                    selectedMultiple.map((i) => i.key),
                                );
                            } }
                            optionsListWidth="field"
                        />

                        <ImageUploader
                            title="Poster *"
                            value={ values.poster ? [values.poster] : [] }
                            onUpdate={ (value) => {
                                setFieldValue('poster', value[0]);
                            } }
                            multiple={ false }
                            error={ touched.poster && errors.poster }
                            type={ ImageTypeEnum.POSTER }
                            uploadRequest={ uploads.uploadImage }
                        />
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
